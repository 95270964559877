import TermsLabel from 'components/TermsLabel';
import React, { FC, useEffect, useState } from 'react';
import ReactGA from 'react-ga4-pro';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import PasswordStrengthBar from 'react-password-strength-bar';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { Box, Button, CircularProgress, Grid, InputAdornment, styled, Typography } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { useSnackbar } from 'notistack';

import { ErrorMessage, Field, Form, Formik, FormikValues } from 'formik';
import { CheckboxWithLabel, TextField } from 'formik-mui';
import * as Yup from 'yup';

import { useLazyCheckEmailAvailabilityQuery, useSignUpMutation } from 'services/authService';
import LoadingButton from 'components/LoadingButton';
import { FormikOnSubmit, prepareFormikValues } from 'utils/prepareValues';
import Loader from 'components/Loader';

const ErrorMessageWrapper = styled(ErrorMessage)(({ theme }) => ({
    color: theme.colors.error.main,
    margin: '3px 8px 0',
    fontWeight: 'bold',
    fontSize: 13,
    lineHeight: 1.66
}));

const SignUpForm: FC = () => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const { executeRecaptcha } = useGoogleReCaptcha();

    const [signup, { isSuccess, isError }] = useSignUpMutation();

    const [checkEmailAvailability, { isFetching, isUninitialized }] = useLazyCheckEmailAvailabilityQuery();
    const [checkEmailState, setCheckEmailState] = useState({ isAvailable: false, lastChecked: '' });

    const [confirmationEmail, setConfirmationEmail] = useState('');

    useEffect(() => {
        if (isError) enqueueSnackbar(t('Error'), { variant: 'error' });
    }, [isError]);

    const initialValues: FormikValues = {
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        confirm_password: '',
        terms: false
    };

    const validationSchema = Yup.object().shape({
        first_name: Yup.string().required(t('This field is required')),
        last_name: Yup.string().required(t('This field is required')),
        email: Yup.string().email(t('Invalid email')).required(t('This field is required')),
        password: Yup.string()
            .min(8, t('Password must be at least 8 characters long'))
            .max(64, t('Password can be at most 64 characters long'))
            .matches(/[a-z]/, t('Password must have at least one lowercase letter'))
            .matches(/[A-Z]/, t('Password must have at least one uppercase letter'))
            .matches(/[0-9]/, t('Password must have at least one digit'))
            .matches(/[●!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]/, t('Password must have at least one special character'))
            .required(t('This field is required')),
        confirm_password: Yup.string()
            .required(t('This field is required'))
            .oneOf([Yup.ref('password')], t('Passwords do not match')),
        terms: Yup.boolean().required(t('is required')).oneOf([true], t('This field is required'))
    });

    const validateEmail = async (email: string) => {
        let error = undefined as string | undefined;
        if (email && email !== checkEmailState.lastChecked) {
            const { isError } = await checkEmailAvailability(email);
            setCheckEmailState({ isAvailable: !isError, lastChecked: email });
            if (isError) error = t('Email is already taken');
        }
        return error;
    };

    if (!executeRecaptcha) {
        return <Loader />;
    }

    const submit: FormikOnSubmit = async ({ first_name, last_name, email, password }, formikActions) => {
        const tenant = 'cmit';
        const recaptcha = await executeRecaptcha();
        try {
            await signup({ first_name, login: email, last_name, email, password, tenant, recaptcha }).unwrap();
            ReactGA.event('sign_up');
        } catch {
            enqueueSnackbar(t('Sign up failed.'), { variant: 'error' });
        }
        formikActions.setSubmitting(false);
        setConfirmationEmail(email);
    };

    return (
        <>
            {isSuccess && confirmationEmail ? (
                <Box display="flex" flexDirection="column" alignItems="center">
                    <EmailIcon fontSize="large" sx={{ height: 64, width: 64 }} />
                    <Typography my={1} variant="h3">
                        {t('Confirm your email address!')}
                    </Typography>

                    <Typography my={1} color="textSecondary">
                        {t('A confirmation email has been sent to:')}
                    </Typography>

                    <Typography my={1} fontWeight="bold" color="textSecondary">
                        {confirmationEmail}
                    </Typography>

                    <Typography my={1} color="textSecondary">
                        {t('Check your inbox and click on the verification link to confirm your email address.')}
                    </Typography>

                    <Button sx={{ my: 2 }} variant="outlined" component={RouterLink} to="/">
                        {t('Go to homepage')}
                    </Button>
                </Box>
            ) : (
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={prepareFormikValues(submit)}
                    validateOnChange={false}
                    validateOnBlur
                >
                    {({ isSubmitting, values }) => (
                        <Form autoComplete="off">
                            <Grid container spacing={4}>
                                <Grid item xs={12} md={6}>
                                    <Field
                                        fullWidth
                                        name="first_name"
                                        autoComplete="off"
                                        component={TextField}
                                        label={t('First name')}
                                        placeholder={`${t('Enter your first name')}...`}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Field
                                        fullWidth
                                        name="last_name"
                                        autoComplete="off"
                                        component={TextField}
                                        label={t('Last name')}
                                        placeholder={`${t('Enter your last name')}...`}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Field
                                        fullWidth
                                        name="email"
                                        autoComplete="false"
                                        component={TextField}
                                        label={t('Email')}
                                        placeholder={`${t('Enter your email')}...`}
                                        validate={validateEmail}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    {isUninitialized ? (
                                                        <></>
                                                    ) : isFetching ? (
                                                        <CircularProgress size="1rem" />
                                                    ) : checkEmailState.isAvailable ? (
                                                        <CheckCircleOutlineIcon color="success" />
                                                    ) : (
                                                        <HighlightOffIcon color="error" />
                                                    )}
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Field
                                        fullWidth
                                        type="password"
                                        name="password"
                                        autoComplete="new-password"
                                        component={TextField}
                                        label={t('Password')}
                                        placeholder={`${t('Enter your password')}...`}
                                        FormHelperTextProps={{
                                            component: PasswordStrengthBar,
                                            minLength: 8,
                                            password: values.password,
                                            error: false,
                                            sx: !values.password?.length ? { display: 'none' } : null
                                        }}
                                        helperText={' '}
                                    />
                                    <ErrorMessageWrapper name="password" component="p" />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Field
                                        fullWidth
                                        type="password"
                                        autoComplete="new-password"
                                        name="confirm_password"
                                        component={TextField}
                                        label={t('Confirm password')}
                                        placeholder={`${t('Confirm your password')}...`}
                                    />
                                </Grid>
                                <Grid item xs={12} pt={0}>
                                    <Field
                                        name="terms"
                                        type="checkbox"
                                        component={CheckboxWithLabel}
                                        Label={{
                                            label: <TermsLabel />
                                        }}
                                    />
                                    <ErrorMessageWrapper name="terms" component="p" />
                                </Grid>
                                <Grid item xs={12} textAlign="center">
                                    <LoadingButton
                                        color="primary"
                                        loading={isSubmitting}
                                        disabled={isSubmitting}
                                        type="submit"
                                        size="large"
                                        variant="contained"
                                        sx={{ width: 0.5 }}
                                    >
                                        {t('Sign up')}
                                    </LoadingButton>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            )}
        </>
    );
};

export default SignUpForm;

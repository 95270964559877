import { useAuth } from 'hooks/useAuth';
import { createContext, FC, PropsWithChildren, useState } from 'react';

interface ISidebarContext {
    isSidebarOpen: boolean;
    toggleSidebar: () => void;
    closeSidebar: () => void;

    isVideoMinimized: boolean;
    toggleVideoMinimized: () => void;

    isContextMenuOpen: boolean;
    toggleContextMenu: () => void;
    setIsVideoMinimized: (minimized: boolean) => void;

    isSidebarLocked: boolean;
    toggleSidebarLocked: () => void;

    isAudioSilenced: boolean;
    toggleAudioSilenced: () => void;
    setIsAudioSilenced: (silenced: boolean) => void;
}

export const SidebarContext = createContext<ISidebarContext>({} as ISidebarContext);

export const SidebarProvider: FC<PropsWithChildren> = ({ children }) => {
    const { isaMode } = useAuth();
    const [isSidebarLocked, setIsSidebarLocked] = useState(true);
    const toggleSidebarLocked = () => {
        setIsSidebarLocked(!isSidebarLocked);
    };

    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const toggleSidebar = () => {
        if (!isSidebarLocked) setIsSidebarOpen(!isSidebarOpen);
    };
    const closeSidebar = () => {
        setIsSidebarOpen(false);
    };

    const [isVideoMinimized, setIsVideoMinimized] = useState<boolean>(!!isaMode);
    const toggleVideoMinimized = () => {
        setIsVideoMinimized(!isVideoMinimized);
    };

    const [isContextMenuOpen, setIsContextMenuOpen] = useState<boolean>(false);
    const toggleContextMenu = () => {
        setIsContextMenuOpen(!isContextMenuOpen);
    };

    const [isAudioSilenced, setIsAudioSilenced] = useState<boolean>(false);
    const toggleAudioSilenced = () => {
        setIsAudioSilenced(!isAudioSilenced);
    };

    return (
        <SidebarContext.Provider
            value={{
                isSidebarOpen,
                toggleSidebar,
                closeSidebar,

                isVideoMinimized,
                toggleVideoMinimized,
                setIsVideoMinimized,

                isContextMenuOpen,
                toggleContextMenu,

                isSidebarLocked,
                toggleSidebarLocked,

                isAudioSilenced,
                toggleAudioSilenced,
                setIsAudioSilenced
            }}
        >
            {children}
        </SidebarContext.Provider>
    );
};

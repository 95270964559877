import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from 'models/user';

type AuthState = {
    initialized: boolean;
    user: User | null;
    token: string | null;
    courseMode?: boolean;
    workMode?: boolean;
    isaMode?: boolean;
};

const initialState: AuthState = {
    initialized: false,
    user: null,
    token: null,
    courseMode:
        window.location.hostname.match(/app.*-courses/)?.length === 1 ||
        window.location.hostname.match(/app.*-isa/)?.length === 1 ||
        window.location.hostname.includes('localhost'),
    workMode: window.location.hostname.match(/app.*-work/)?.length === 1, // || window.location.hostname.includes('localhost'),
    isaMode: window.location.hostname.match(/app.*-isa/)?.length === 1 // || window.location.hostname.includes('localhost')
};

const slice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        initAuth: () => initialState,
        setCredentials: (state, { payload }: PayloadAction<Partial<AuthState>>) => ({
            ...state,
            ...payload,
            initialized: true
        }),
        clearCredentials: () => ({ ...initialState, initialized: true })
    }
});

export const { initAuth, setCredentials, clearCredentials } = slice.actions;

export default slice.reducer;

import { BaseQueryFn } from '@reduxjs/toolkit/query';
import { AxiosError, AxiosRequestConfig } from 'axios';
import { Environment } from 'environment';
import { clearCredentials } from 'store/slices/authSlice';
import axios from 'utils/axios';

export interface AxiosBaseQueryError {
    status: number;
    data: string;
}

const axiosBaseQuery =
    (
        { basePath }: { basePath: string } = { basePath: '' }
    ): BaseQueryFn<AxiosRequestConfig & { basePathOverride?: string }, unknown, unknown> =>
    async ({ url: path = '', basePathOverride, ...rest }, api) => {
        try {
            const { data } = await axios({
                url: Environment.apiUrl + (basePathOverride ?? basePath) + path,
                ...rest
            });
            return { data };
        } catch (axiosError) {
            const { response, message } = axiosError as AxiosError;

            // TODO: Refresh token on 403 and 401, excluding login
            if (response && [401, 403].includes(response.status) && path !== '/auth/login') {
                api.dispatch(clearCredentials);
                window.location.href = '/login';
            }

            return {
                error: {
                    status: response?.status,
                    data: response?.data || message
                }
            };
        }
    };

export default axiosBaseQuery;

import { Formik } from 'formik';
import { ComponentProps } from 'react';

export type FormikOnSubmit = ComponentProps<typeof Formik>['onSubmit'];

export const prepareFormikValues =
    (onSubmit: FormikOnSubmit): FormikOnSubmit =>
    (values, formikHelpers) => {
        const newValues = { ...values };
        Object.keys(values).forEach((key) => {
            if (typeof values[key] === 'string') {
                newValues[key] = values[key].trim();
            }
            if (['login', 'email'].includes(key)) {
                newValues[key] = values[key].toLowerCase();
            }
        });
        return onSubmit(newValues, formikHelpers);
    };
